module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Roswitha Kühn - Portfolio","short_name":"rkuehn","description":"Portfolio von Roswitha Kühn. Neue und alte Projekte, von Malerei über Kunstdruck bis Fotografie.","start_url":"/","background_color":"#ffffff","theme_color":"#b75e09","display":"standalone","icon":"src/images/icon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"6822b403a94b1b23b58ba8f20bb11aaf"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
